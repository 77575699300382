import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-Y91BEBJ9ZV', { debug: false });
};

export const logPageView = (eateryName) => {
  const additionalData = {
    page: window.location.pathname,
    eatery: eateryName
  };

  console.log("sending pageview event", eateryName);
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

export const trackButtonClick = (buttonName) => {
  ReactGA.event({
    category: "Social Media",
    action: `Clicked ${buttonName}`
  });
};