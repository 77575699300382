import { cilCheckAlt, cilLockUnlocked, cilPeople, cilPhone, cilShareAlt } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CImage,
    CInputGroup,
    CInputGroupText,
    CRow,
    CSpinner,
    CToast,
    CToastBody,
    CToaster
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import emptyDoodle from "../../assets/images/order.png";
import '../../css/loader.css';
import useTheme from "../../hooks/useTheme";
import useApiDataStore from "../../useApiDataStore";
import OrderItem from "./OrderItem";
import Loader from "../../components/Loader";

const Order = () => {
    const { info, userId, menu, order, table, confirmOrder, openOrder, tableIdOrOrderId } = useApiDataStore();
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);
    const [toasts, setToasts] = useState([]);

    const phoneRegex = /^0[567]\d{8}$|^0[1234]\d{8}$/;
    const [phoneNumber, setPhoneNumber] = useState(order?.contact || "");
    const [isPhoneValid, setIsPhoneValid] = useState(order?.contact ? phoneRegex.test(order.contact) : false);

    const addToast = (message, color = 'success') => {
        setToasts([
            ...toasts,
            { message, color, id: Date.now() },
        ]);
    };

    const handleClickOrder = async () => {
        setIsLoading(true);
        try {
            await confirmOrder(phoneNumber);
            addToast("Commande envoyée avec succès!");
        } catch (error) {
            console.error("Failed to confirm order:", error);
            addToast("Échec de l'envoi de la commande.", 'danger');
        } finally {
            setIsLoading(false);
        }
    };

    const handleClickOpenOrder = async () => {
        setIsLoading(true);
        try {
            await openOrder();
            addToast("Vous pouvez modifier votre commande!");
        } catch (error) {
            console.error("Failed to open order for editing:", error);
            addToast("Échec de la réouverture de la commande.", 'danger');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePhoneChange = (e) => {
        const input = e.target.value;
        setPhoneNumber(input);
        setIsPhoneValid(phoneRegex.test(input));
    };

    // TODO move elsewhere
    function findObjectById(data, targetId) {
        for (const category in data) {
            if (data.hasOwnProperty(category)) {
                const items = data[category];
                const foundItem = items.find((item) => item.id === targetId);
                if (foundItem) {
                    return foundItem;
                }
            }
        }
        return null;
    }

    function renderOrderItems(order, menu) {
        return order?.foodItems?.map((orderItem, index) => {
            const menuItem = findObjectById(menu, orderItem.id);
            return (
                <React.Fragment key={orderItem.key}>
                    <OrderItem
                        id={orderItem.id}
                        itemKey={orderItem.key}
                        name={menuItem?.name || "Article inconnu"}
                        photo={menuItem?.photo || ""}
                        price={menuItem?.price}
                        totalPrice={orderItem.totalPrice}
                        currency={info?.currency}
                        requiredChoices={orderItem.requiredChoices}
                        otherSizes={orderItem.otherSizes}
                        extras={orderItem.extras}
                        quantity={orderItem.quantity}
                        additionalNote={orderItem.additionalNote}
                    />
                    {index < order.foodItems.length - 1 && (
                        <hr color="lightgrey" style={{ marginY: 10 }} />
                    )}
                </React.Fragment>
            );
        });
    }

    const renderPeopleList = () => {
        const allPeople = order.people.some(person => person.startsWith(userId))
            ? order.people
            : [...order.people, userId];

        const s = allPeople.length > 1 ? "s" : "";

        const stringText = `${allPeople.length} personne${s} connectée${s}`;
        if (allPeople.length > 1) {
            return <p><CIcon icon={cilPeople} /> {stringText}</p>
        } else {
            return <></>;
        }
    };

    const handleShare = () => {
        const url = `${window.location.origin}/${order.eateryId}/${tableIdOrOrderId || table?.id || order.id}/order`;
        if (navigator.share) {
            navigator.share({
                title: 'Partager votre commande en cours',
                text: table?.name
                    ? `Table ${table.name} - ${info.name}`
                    : `Commande à emporter #${order.id.substring(order.id.length - 2)} - ${info.name}`,
                url: url,
            }).catch((error) => console.error('Error sharing:', error));
        } else {
            // Fallback for browsers that don't support the Web Share API
            navigator.clipboard.writeText(url)
                .then(() => alert('Lien copié!'))
                .catch((error) => console.error('Failed to copy:', error));
        }
    };

    document.title = `${info.name} - Commande`;

    if (!order?.id) {
        return <Loader color={theme?.palette?.primary} />;
    }

    return (
        <CContainer
            fluid
            className="g-1 p-1 d-flex flex-column"
            style={{
                color: theme.palette.textPrimary,
                height: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.8)",
                paddingBottom: '10vh'
            }}
        >
            {/* Title */}
            <CRow className="g-0 mt-4 mb-0 px-4 d-flex flex-row w-100 justify-content-center align-items-center">
                <CCol xs={8}>
                    <h3
                        className="d-flex justify-content-start align-items-center"
                        style={{
                            fontWeight: "bold",
                        }}
                    >
                        Récapitulatif
                    </h3>
                    {table?.name != null ? (
                        <h4 className="d-flex justify-content-start align-items-center">
                            Table {table.name}
                            <CIcon
                                icon={cilShareAlt}
                                size="lg"
                                className="ms-2"
                                onClick={handleShare}
                                style={{ cursor: 'pointer' }}
                            />
                        </h4>
                    )
                        : <h4 className="d-flex justify-content-start align-items-center">
                            Commande à emporter #{order.id.substring(order.id.length - 2)}
                            <CIcon
                                icon={cilShareAlt}
                                size="lg"
                                className="ms-2"
                                onClick={handleShare}
                                style={{ cursor: 'pointer' }}
                            />
                        </h4>}
                    {userId && <div className="d-flex justify-content-start align-items-center">Mon code: {userId.substring(0, 4)}</div>}
                    <div className="d-flex justify-content-start align-items-center">
                        {renderPeopleList()}
                    </div>
                </CCol>
                <CCol xs={4}>
                    <picture>
                        {info.branding.logo.webp && <source srcSet={info.branding.logo.webp} type="image/webp" />}
                        {info.branding.logo.avif && <source srcSet={info.branding.logo.avif} type="image/avif" />}
                        {info.branding.logo.jpg && <source srcSet={info.branding.logo.jpg} type="image/jpg" />}
                        <CImage
                            srcSet={`${info.branding.logo.jpg},${info.branding.logo.webp}`}
                            alt={"Logo"}
                            loading="lazy"
                            className="p-0 w-100"
                        />
                    </picture>
                </CCol>
            </CRow>
            <CCol className="w-100 g-2 px-1 pt-4">
                {/* Order Food Items */}
                <CCol className="mb-1 mx-1">
                    {menu && order?.foodItems.length !== 0 && (
                        <CCard
                            className="w-100 h-100"
                            style={{ background: theme.palette.background }}
                        >
                            <CCardBody className="h-100 d-flex flex-column">
                                <CContainer fluid className="g-0 h-100">
                                    {renderOrderItems(order, menu)}
                                </CContainer>
                            </CCardBody>
                        </CCard>
                    )}
                    {(!menu || order?.foodItems.length === 0) && (
                        <div className="pt-2">
                            <CImage
                                className="my-3"
                                src={emptyDoodle}
                                align="center"
                                style={{
                                    height: "40vh",
                                    maxWidth: "100%",
                                    objectFit: "cover",
                                }}
                            />
                            <h4 style={{ textAlign: "center" }}>
                                Vous n'avez encore rien selectionné.
                                <br />
                                Trouvez ce qui vous plait dans le menu !
                            </h4>
                            <Link to="../menu" style={{ textDecoration: "none" }}>
                                <div className="py-4 w-100 d-flex justify-content-center">
                                    <CButton
                                        color="light"
                                        size="lg"
                                        className="text-uppercase font-weight-bold py-3 mt-3"
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "small",
                                            background: theme.palette.primary,
                                            color: theme.palette.onPrimary
                                        }}
                                    >
                                        Retour au menu
                                    </CButton>
                                </div>
                            </Link>
                        </div>
                    )}
                </CCol>
                {menu && order?.foodItems.length !== 0 && (
                    <CCol className="px-4 mb-5">
                        <CRow
                            xs={{ cols: 12 }}
                            md={{ cols: 1 }}
                            style={{
                                paddingTop: "10vh",
                                backgroundColor: theme.palette.background,
                            }}
                        >
                            <CCol>
                                <CCard
                                    className={`mb-1 border-0`}
                                    style={{
                                        backgroundColor: theme.palette.background,
                                    }}
                                >
                                    <CCardBody className="px-0">
                                        <CRow className="w-100 m-0">
                                            <CCol className="w-100" xs={12}>
                                                <CCardTitle className="mb-2 pb-2">
                                                    <span
                                                        style={{
                                                            fontSize: 16,
                                                            fontWeight: "bold",
                                                            overflow: "hidden",
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                flex: 1,
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                                whiteSpace:
                                                                    "nowrap",
                                                                color: theme.palette.textPrimary,
                                                            }}
                                                        >
                                                            Sous-total
                                                        </span>
                                                        <span
                                                            className="m-0 p-0"
                                                            style={{
                                                                display: "flex",
                                                                alignContent:
                                                                    "center",
                                                                fontSize: 16,
                                                                color: theme.palette.textPrimary,
                                                            }}
                                                        >
                                                            &nbsp;&nbsp;
                                                            {order.foodItems.reduce(
                                                                (
                                                                    accumulator,
                                                                    orderItem
                                                                ) => {
                                                                    return (accumulator + orderItem.totalPrice);
                                                                },
                                                                0
                                                            )}{" "}
                                                            {info?.currency}
                                                        </span>
                                                    </span>
                                                </CCardTitle>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>

                        {order.status === 'CREATED' &&
                            <>
                                {/* Phone Number Input */}
                                {!table?.id && <CForm style={{ padding: 3 }}>
                                    <CRow className="mb-3">
                                        <CCol>
                                            <CInputGroup>
                                                <CInputGroupText>
                                                    <CIcon icon={cilPhone} size="sm" />
                                                </CInputGroupText>
                                                <CFormInput
                                                    name="phone"
                                                    aria-label="Phone input"
                                                    type="tel"
                                                    id="phone-number"
                                                    placeholder="Téléphone"
                                                    value={phoneNumber}
                                                    onChange={handlePhoneChange}
                                                    style={{
                                                        border: isPhoneValid ? "1px solid green" : "1px solid red",
                                                        backgroundColor: "#f8f8f8",
                                                        padding: "8px",
                                                    }}
                                                />
                                            </CInputGroup>
                                        </CCol>
                                    </CRow>
                                </CForm>}
                                {/* Order Button */}
                                <CRow
                                    size="lg"
                                    className="w-100 g-0 px-4 d-flex justify-content-center font-weight-bold"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "small",
                                    }}
                                >
                                    <CButton
                                        onClick={handleClickOrder}
                                        color="light"
                                        size="lg"
                                        className="text-uppercase font-weight-bold py-3 mt-3"
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "small",
                                            background: theme.palette.primary,
                                            color: theme.palette.onPrimary
                                        }}
                                        disabled={isLoading || (!table?.id && !isPhoneValid)} // Disable button if phone number is invalid
                                    >
                                        {isLoading ? (
                                            <CSpinner size="sm" />
                                        ) : (
                                            "Commander"
                                        )}
                                    </CButton>
                                </CRow>
                            </>
                        }
                        {order.status === 'CONFIRMED' && <CRow
                            size="lg"
                            className="g-0 w-100 px-4 d-flex justify-content-center font-weight-bold"
                            style={{
                                fontWeight: "bold",
                                fontSize: "small",
                            }}
                        >
                            <CButton
                                color="light"
                                size="lg"
                                className="w-100 d-flex justify-content-center align-items-center bg-light text-black text-uppercase font-weight-bold"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "small",
                                }}
                                disabled
                            >
                                <CIcon icon={cilCheckAlt} />
                                <p className="mb-0 px-3">Commande envoyée</p>
                            </CButton>
                            <CRow xs={12} className="d-flex justify-content-center my-3" style={{ backgroundColor: theme.palette.primary }}>
                                <p className="text-center w-100">Un membre du personnel la validera sous peu.</p>
                            </CRow>
                            <CButton
                                onClick={handleClickOpenOrder}
                                color="light"
                                size="lg"
                                className="w-100 d-flex justify-content-center align-items-center bg-light text-black text-uppercase font-weight-bold"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "small",
                                }}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <CSpinner size="sm" />
                                ) : (
                                    <>
                                        <CIcon icon={cilLockUnlocked} />
                                        <p className="mb-0 px-3">Editer ma commande</p>
                                    </>
                                )}
                            </CButton>
                        </CRow>}
                        {order.status === 'VALIDATED' && <CRow
                            size="lg"
                            className="w-100 px-4 d-flex justify-content-center font-weight-bold"
                            style={{
                                fontWeight: "bold",
                                fontSize: "small",
                            }}
                        >
                            <CButton
                                color="light"
                                size="lg"
                                className="w-100 d-flex justify-content-center align-items-center bg-light text-black text-uppercase font-weight-bold"
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "small",
                                }}
                                disabled
                            >
                                <CIcon icon={cilCheckAlt} />
                                <p className="mb-0 px-3">Commande validée</p>
                            </CButton>
                            <CRow xs={12} className="d-flex justify-content-center my-3" style={{ backgroundColor: theme.palette.primary }}>
                                <p className="text-center w-100">Vos plats sont lancés en cuisine. Ils vont bientôt vous être servis.</p>
                            </CRow>
                        </CRow>}
                    </CCol>
                )}
            </CCol>
            <CToaster className="bottom-0">
                {toasts.map((toast) => (
                    <CToast key={toast.id} autohide={2000} visible color={toast.color}>
                        <CToastBody>{toast.message}</CToastBody>
                    </CToast>
                ))}
            </CToaster>
        </CContainer >
    );
};

export default React.memo(Order);
