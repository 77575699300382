import React from 'react';
import { CContainer } from "@coreui/react";
import '../css/loader.css';

function Loader({ color }) {
    return <CContainer className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '90%' }}>
        <div className="lds-grid" style={{ color: color }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </CContainer>;
}

export default Loader;