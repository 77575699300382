import { useMemo } from "react";
import useApiDataStore from "../useApiDataStore";

const defaultTheme = {
    palette: {
        primary: "#6200ea",
        secondary: "#03dac6",
        success: "#4caf50",
        error: "#f44336",
        warning: "#ff9800",
        info: "#2196f3",
        background: "#ffffff",
        surface: "#f5f5f5",
        onPrimary: "#ffffff",
        onSecondary: "#000000",
        onBackground: "#000000",
        onSurface: "#000000",
    },
    typography: {
        fontFamily: "Arial, sans-serif",
        h1: { fontSize: "2rem", fontWeight: "700", lineHeight: "2.5rem" },
        h2: { fontSize: "1.5rem", fontWeight: "600", lineHeight: "2rem" },
        body1: { fontSize: "1rem", fontWeight: "400", lineHeight: "1.5rem" },
        body2: { fontSize: "0.875rem", fontWeight: "400", lineHeight: "1.25rem" },
        caption: { fontSize: "0.75rem", fontWeight: "300", lineHeight: "1rem" },
    },
    spacing: 8,
    shape: { borderRadius: 4 },
    components: {},
};

const useTheme = () => {
    const { branding } = useApiDataStore();

    // Extract the theme from branding with a fallback
    const theme = useMemo(() => branding?.theme || defaultTheme, [branding]);

    return theme;
};

export default useTheme;
