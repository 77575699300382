import { useEffect } from "react";
import useTheme from "../hooks/useTheme";

const CoreUIThemeProvider = ({ children }) => {
    const theme = useTheme();

    useEffect(() => {
        // Update CSS variables dynamically based on the theme
        const root = document.documentElement;

        if (theme.palette) {
            root.style.setProperty("--cui-primary", theme?.palette?.primary.main || theme?.palette?.primary || "#0d6efd");
            root.style.setProperty("--cui-secondary", theme.palette.secondary || "#6c757d");
            root.style.setProperty("--cui-success", theme.palette.success.main || "#198754");
            root.style.setProperty("--cui-info", theme.palette.info || "#0dcaf0");
            root.style.setProperty("--cui-warning", theme.palette.warning || "#ffc107");
            root.style.setProperty("--cui-danger", theme.palette.error || "#dc3545");
            root.style.setProperty("--cui-light", theme.palette.background || "#f8f9fa");
            root.style.setProperty("--cui-dark", theme.palette.surface || "#212529");
        }

        if (theme.typography) {
            root.style.setProperty("--cui-font-family-base", theme.typography.fontFamily || "Arial, sans-serif");
        }

        root.style.setProperty("--cui-border-radius", `${theme.shape?.borderRadius || 4}px`);
    }, [theme]);

    return <>{children}</>;
};

export default CoreUIThemeProvider;
