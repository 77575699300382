import {
    cilDescription,
    cilDinner,
    cilStar
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CBadge, CCol, CNav, CNavItem, CNavLink, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useApiDataStore from "../useApiDataStore";
import useTheme from "../hooks/useTheme";

const BottomNavbar = ({ activeKeyIndex = 1 }) => {
    const [activeKey, setActiveKey] = useState(activeKeyIndex);
    const { branding, order } = useApiDataStore();
    const theme = useTheme();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("menu")) {
            setActiveKey(1);
        } else if (location.pathname.includes("order")) {
            setActiveKey(2);
        } else if (location.pathname.includes("rate")) {
            setActiveKey(3);
        }
    }, [location]);

    const getGlowStyle = (isActive) => isActive ? {
        textShadow: `0 0 10px ${theme?.palette?.primary.main || theme?.palette?.primary}, 0 0 20px ${theme.palette.success.primary}`,
        filter: `drop-shadow(0 -10px 6px ${theme?.palette?.primary.main || theme?.palette?.primary})`,
        transition: 'all 0.3s ease'
    } : {};

    return (
        <CNav
            className="d-flex justify-content-evenly align-items-center px-3"
            style={{
                height: "10vh",
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 10,
                width: '100%',
                background: theme.palette.secondary || theme.palette.success,
                color: theme.palette.onSecondary,
                zIndex: 100,
                boxShadow: `0px ${theme.shadow || 10}px 30px rgba(0, 0, 0, 0.8)`,
                display: 'flex',
            }}
        >
            <Link to="menu" className="text-decoration-none" style={{ flex: 1, display: "flex", justifyContent: 'center', textAlign: 'center' }}>
                <CNavItem role="presentation" onClick={() => setActiveKey(1)}>
                    <CNavLink
                        href="#"
                        active={activeKey === 1}
                        component="button"
                        role="tab"
                        style={{
                            backgroundColor: "transparent",
                            borderRadius: "0",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <CCol className="g-0 justify-content-center align-items-center" style={{ color: activeKey === 1 ? theme.palette.onSecondary : theme.palette.textPrimary }}>
                            <CRow className="g-0 justify-content-center align-items-center">
                                <CIcon
                                    icon={cilDinner}
                                    size="xl"
                                    style={getGlowStyle(activeKey === 1)}
                                />
                            </CRow>
                            <CRow>
                                <small
                                    style={{
                                        fontSize: "small",
                                        ...getGlowStyle(activeKey === 1)
                                    }}
                                >
                                    Menu
                                </small>
                            </CRow>
                        </CCol>
                    </CNavLink>
                </CNavItem>
            </Link>
            {branding.showRecapScreen && (

                <Link to="order" className="position-relative text-decoration-none" style={{ flex: 1, display: "flex", justifyContent: 'center', textAlign: 'center' }}>
                    <CNavItem role="presentation" onClick={() => setActiveKey(2)}>
                        <CNavLink
                            href="#"
                            active={activeKey === 2}
                            component="button"
                            role="tab"
                            className="position-relative"
                            style={{
                                backgroundColor: "transparent",
                                borderRadius: "0",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <CCol className="g-0" style={{ color: activeKey === 2 ? theme.palette.onSecondary : theme.palette.textPrimary }}>
                                <CRow className="g-0 justify-content-center align-items-center">
                                    <div style={{ width: 'fit-content', maxWidth: 'fit-content', position: 'relative' }}>
                                        <CIcon
                                            icon={cilDescription}
                                            size="xl"
                                            style={getGlowStyle(activeKey === 2)}
                                        />
                                        {order && order.status !== 'VALIDATED' && Object.keys(order.foodItems).length > 0 && (
                                            <CBadge
                                                position="top-end"
                                                shape="rounded-pill"
                                                style={{
                                                    backgroundColor: theme.palette.secondary,
                                                    color: activeKey === 2 ? theme.palette.onSecondary : theme.palette.textPrimary,
                                                    position: 'absolute',
                                                    top: '-10px',
                                                    right: '-10px'
                                                }}
                                            >
                                                {order.foodItems.map(item => item.quantity).reduce((acc, qty) => acc + qty, 0)}
                                            </CBadge>
                                        )}
                                    </div>
                                </CRow>
                                <CRow>
                                    <small
                                        style={{
                                            fontSize: "small",
                                            ...getGlowStyle(activeKey === 2)
                                        }}
                                    >
                                        Commande
                                    </small>
                                </CRow>
                            </CCol>
                        </CNavLink>
                    </CNavItem>
                </Link>
            )}

            <Link to="rate" className="text-decoration-none" style={{ flex: 1, display: "flex", justifyContent: 'center', textAlign: 'center' }}>
                <CNavItem role="presentation" onClick={() => setActiveKey(3)}>
                    <CNavLink
                        href="#"
                        active={activeKey === 3}
                        component="button"
                        role="tab"
                        style={{
                            backgroundColor: "transparent",
                            borderRadius: "0",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <CCol className="g-0 justify-content-center align-items-center" style={{ color: activeKey === 3 ? theme.palette.onSecondary : theme.palette.textPrimary }}>
                            <CRow className="g-0 justify-content-center align-items-center">
                                <CIcon
                                    icon={cilStar}
                                    size="xl"
                                    style={getGlowStyle(activeKey === 3)}
                                />
                            </CRow>
                            <CRow>
                                <small
                                    style={{
                                        fontSize: "small",
                                        ...getGlowStyle(activeKey === 3)
                                    }}
                                >
                                    Avis
                                </small>
                            </CRow>
                        </CCol>
                    </CNavLink>
                </CNavItem>
            </Link>
        </CNav>
    );
};

export default React.memo(BottomNavbar);