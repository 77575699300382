import { CCard, CCardTitle, CCol, CRow } from "@coreui/react";
import React, { useEffect } from "react";
import useTheme from "../../../hooks/useTheme";
import useApiDataStore from "../../../useApiDataStore";
import FoodItemCard2 from "./FoodItemCard2";
import Loader from "../../../components/Loader";

const FoodItemList2 = ({ menuData, selectedCategory, setSelectedCategory, setFocusedCategory }) => {
    const { order, categories } = useApiDataStore();
    const theme = useTheme();

    function findCategoryById(id) {
        return categories.find(c => c.id === id);
    }

    const textStyle = {
        color: theme.palette.textPrimary,
        fontFamily: theme.typography.fontMappings.heading || 'DM Serif Display, serif',
        fontWeight: 400,
        fontSize: '36px'
    };

    useEffect(() => {
        const handleScroll = () => {

            const anchors = document.querySelectorAll('.category-section a[id]');
            let closestAnchor = null;
            let minTopDistance = Infinity;

            anchors.forEach((anchor) => {
                const rect = anchor.getBoundingClientRect();
                // Check distance from the top of the viewport
                // We use Math.abs so that sections slightly above the viewport are also considered
                const topDistance = Math.abs(rect.top);

                // Only consider elements that are above or near the top of the viewport
                if (rect.top <= 100 && topDistance < minTopDistance) {
                    closestAnchor = anchor;
                    minTopDistance = topDistance;
                }
            });

            if (closestAnchor) {
                setFocusedCategory(closestAnchor.id);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [setFocusedCategory]);

    useEffect(() => {
        // scroll to the selected category
        // Add a small delay to ensure the DOM is ready
        const timeoutId = setTimeout(() => {
            const categorySection = document.getElementById(selectedCategory);
            if (categorySection) {
                categorySection.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
            }
        }, 500);

        // Cleanup timeout on unmount or when selectedCategory changes
        return () => clearTimeout(timeoutId);
    }, [selectedCategory]);

    const handleCategoryClick = (e, categoryId) => {
        e.preventDefault();
        setSelectedCategory(categoryId);
    };

    function findOrderItemIndex(id) {
        if (order) {
            return order.foodItems.findIndex((item) => item.id === id)
        } else return -1;
    }

    if (!theme || !categories) {
        return <Loader color={theme?.palette?.primary.main || theme?.palette?.primary} />;
    }

    return (
        <>
            {/* Render Menu Data */}
            {Object.keys(menuData).map((categoryId) => (
                <div
                    className="p-2 category-section"
                    data-category-id={categoryId}
                    key={categoryId}
                    style={{
                        color: theme.palette.textPrimary,
                    }}
                >
                    <a
                        id={categoryId}
                        href={`#${categoryId}`}
                        onClick={(e) => handleCategoryClick(e, categoryId)}
                        aria-label={`Navigate to category ${findCategoryById(categoryId)?.displayName}`}
                        style={{ textDecoration: 'none' }}
                    >
                        <CCard
                            className="w-10 mb-3 bg-transparent border-none pl-3 text-white d-flex align-items-center justify-content-center"
                            style={{ border: 0, height: "100%" }}
                        >
                            <CCardTitle
                                className="px-3 py-5"
                                style={{
                                    ...textStyle,
                                    textAlign: "center",
                                }}
                            >
                                {findCategoryById(categoryId)?.displayName}
                            </CCardTitle>
                        </CCard>
                    </a>

                    {/* Filtered food items based on search query */}
                    <CRow className="g-0 d-flex justify-content-center">
                        {menuData[categoryId].map((foodItem) => (
                            <CCol xs="12" md="6" key={foodItem.id} className="mb-3">
                                <FoodItemCard2
                                    key={foodItem.id}
                                    id={foodItem.id}
                                    isSelected={findOrderItemIndex(foodItem.id) !== -1}
                                />
                            </CCol>
                        ))}
                    </CRow>
                </div>
            ))}
        </>
    );
};

export default React.memo(FoodItemList2);
