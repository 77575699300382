import { CButton, CCol, CContainer, CImage } from "@coreui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import forknifeLogo from "../../assets/images/forknife.png";
import useApiDataStore from "../../useApiDataStore";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";

const Start = () => {
  const { theme, info, getEateryInfo } = useApiDataStore();
  const { eateryId } = useParams();

  const circleStyle = {
    height: '100%',
    //width: '100%',
    aspectRatio: '1/1',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  };

  useEffect(() => {
    if (!theme) {
      //getEateryInfo(eateryId);
    }
  });

  const w = window.innerWidth;
  const h = window.innerHeight;

  if (!theme) {
    return (
      <CContainer sm className="g-0" style={{ height: '100%', width: '100%', display: 'block' }}>
        <CCol style={{ height: '85%', width: '100%' }} className="p-2 pt-5">
          <ContentLoader
            speed={2}
            width={w}
            height={h}
            viewBox={`0 0 ${w} ${h}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            {/* Placeholder for image */}
            <circle cx={window.innerWidth / 2} cy="110" r="90" />

            {/* Placeholder for title */}
            <rect x="20" y="250" rx="3" ry="3" width="80%" height="20" />

            {/* Placeholder for description */}
            <rect x="20" y="280" rx="3" ry="3" width="350" height="10" />

            {/* Placeholder for button */}
            <rect x="20" y="300" rx="3" ry="3" width="100" height="30" />
          </ContentLoader>
        </CCol>
        <CCol style={{ height: '10%' }} className="m-2 p-2 d-flex flex-row justify-content-end align-items-center">
          <ContentLoader
            speed={2}
            width={400}
            height={50}
            viewBox="0 0 400 50"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            {/* Placeholder for powered by text */}
            <rect x="20" y="10" rx="3" ry="3" width="250" height="10" />

            {/* Placeholder for flag emoji */}
            <rect x="300" y="10" rx="3" ry="3" width="30" height="30" />
          </ContentLoader>
        </CCol>
      </CContainer>
    );
  }

  return (
    <CContainer
      sm
      className="g-0"
      style={{ height: '100%', width: '100%', backgroundColor: theme.backgroundColor, color: theme.textColor, display: "block" }}
    >
      <CCol
        style={{ height: "85%", width: '100%' }}
        className="d-flex flex-column justify-content-center align-items-center p-2 pt-5"
      >
        <div style={circleStyle}>
          <CImage
            src={`data:image/jpeg;base64,${theme.logo}`}
            style={imageStyle}
          />
        </div>
        <div style={{ height: '75%' }} className="d-flex flex-column justify-content-center align-items-center">
          <h1 style={{ fontWeight: "bold" }} className="my-4">
            Bienvenue
          </h1>
          <h6 style={{ textAlign: 'center' }}>
            {theme.description}
          </h6>
        </div>
        <div className="h-25 w-100 py-3 d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "3rem", color: theme.textColor }}>
          <Link to="menu" className="w-100" style={{ color: theme.primaryColor }}>
            <CButton
              size="lg"
              className="w-100 text-uppercase font-weight-bold"
              style={{
                backgroundColor: theme.activeColor,
                color: theme.activeTextColor,
                borderColor: theme.borderColor,
                fontWeight: "bold",
                fontSize: "small"
              }}
            >
              Voir la carte
            </CButton>
          </Link>
        </div>
      </CCol>
      <CCol
        style={{ height: "10%" }}
        className="m-2 p-2 d-flex flex-row justify-content-end align-items-center"
      >
        <div style={{ flex: 1 }}>
          Powered by&nbsp;&nbsp;&nbsp;
          <CImage src={forknifeLogo} style={{ height: 23.92, width: 117.74 }} />
          <br />
          {info.address.country === "Algeria" && <span>
            Made in 🇩🇿 with ❤️
          </span>}
        </div>
      </CCol>
    </CContainer>
  );
};

export default React.memo(Start);
