import React, { useEffect } from "react";
import useApiDataStore from "../../useApiDataStore";
import Start from "./Start";
import Start2 from "./Start2";
import Start3 from "./Start3";

const StartScreenPicker = () => {
    const { branding } = useApiDataStore();

    useEffect(() => {
        const shouldAddFontLink = true;

        if (shouldAddFontLink) {
            let linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,800&display=swap';

            document.head.appendChild(linkElement);

            return () => {
                document.head.removeChild(linkElement);
            };
        }
    }, []);

    if (!branding) {
        return "";
    } else {
        switch (branding.startScreenVersion) {
            case 3: return <Start3 />
            case 2: return <Start2 />
            default: return <Start />
        }
    }
};

export default React.memo(StartScreenPicker);
