import { CButton, CCol, CContainer, CImage, CRow } from "@coreui/react";
import { Link, useRouteError } from "react-router-dom";
import forknifeLogo from "../assets/images/forknife.png";
import errorDoodle from "../assets/images/error.svg";
import useTheme from "../hooks/useTheme";
import useApiDataStore from "../useApiDataStore";

export default function ErrorPage() {
  const { info } = useApiDataStore();
  const theme = useTheme();
  const error = useRouteError();
  console.error(error);

  return (
    <CContainer
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <CCol
        className="d-flex flex-column justify-content-evenly"
        style={{ height: "80vh" }}
      >
        <CRow className="w-100 mb-5 pb-5 d-flex flex-column justify-content-center align-items-center g-0">
          <CImage src={forknifeLogo} style={{ height: 23.92, width: 117.74 }} />
        </CRow>
        <h2>Hmm... Il semblerait que cette page n’existe pas.</h2>
        <CImage
          src={errorDoodle}
          align="center" /* style={{ minHeight: '50vh', maxWidth: '100%', objectFit: 'cover' }} */
        />
        <CRow className="w-100 mb-5 pb-5 d-flex flex-column justify-content-center align-items-center g-0">
          <Link to={`${info.id}/menu`}>
            <CButton
              color="light"
              size="lg"
              className="text-uppercase font-weight-bold py-3 mt-3"
              style={{
                fontWeight: "bold",
                fontSize: "small",
                background: theme?.palette?.primary.main || theme?.palette?.primary,
                color: theme.palette.onPrimary
              }}
            >
              Retour au menu
            </CButton>
          </Link>
        </CRow>
      </CCol>
    </CContainer>
  );
}
