import React from "react";
import ReactDOM from "react-dom/client";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import { config } from './config.js';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./scss/style.scss";
import ErrorPage from "./views/ErrorPage";
import ContactLinks from "./views/contactLinks/ContactLinks";
import FoodItemDetails from "./views/foodItemDetails/FoodItemDetails";
import Menu from "./views/menu/Menu";
import Order from "./views/order/Order";
import Rate from "./views/rate/Rate";
import Settings from "./views/settings/Settings";
import StartScreenPicker from "./views/start/StartScreenPicker.js";
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { getCookie } from "./utils/cookies.js";
import OrderTypePicker from "./views/start/OrderTypePicker.js";

// posthog.init('phc_ezMsjtRCYFhJ3bj1IjyRonSopTkcTOCGAUCXpBITRzq',
//   {
//     api_host: 'https://us.i.posthog.com',
//     persistence: 'localStorage',
//     person_profiles: 'always', // or 'identified_only' to create profiles for authenticated users only
//     loaded: function () {
//       console.log("PostHog loaded successfully!");
//     },
//     on_error: function (err) {
//       console.error("PostHog initialization error:", err);
//     }
//   }
// );

var url = window.location.href;
var pathSegments = url.split('/');
var eateryId = pathSegments[3] || config.DEMO_EATERY_ID;
var tableIdOrOrderId = pathSegments[4] || getCookie('tableIdOrOrderId') || '';

if (!eateryId) {
  eateryId = config.DEMO_EATERY_ID;
}

const initialPath = `/${eateryId}/${tableIdOrOrderId}`;
console.log(process.env.NODE_ENV, initialPath)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={initialPath} replace />, // Redirect root to the calculated initial path
  },
  {
    path: "/:eateryId/:tableIdOrOrderId?",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <OrderTypePicker />,
      },
      {
        path: "start",
        element: <StartScreenPicker />,
      },
      {
        path: "menu",
        element: <Menu />,
      },
      {
        path: "menu/item/:id",
        element: <FoodItemDetails />,
      },
      {
        path: "links",
        element: <ContactLinks />,
      },
      {
        path: "rate",
        element: <Rate />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "error",
        element: <ErrorPage />,
      }
    ],
  },
])

// const currentPath = window.location.pathname;
// if (currentPath === "" || currentPath === `/` || currentPath === `/${eateryId}/${tableIdOrOrderId}/` || currentPath === `/${eateryId}`) {
//   console.log("navigate")
//   router.navigate(initialPath);
// }

ReactDOM.createRoot(document.getElementById("root")).render(
  //<React.StrictMode>
  <PostHogProvider
    client={posthog}
  >
    <RouterProvider router={router} />
  </PostHogProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
