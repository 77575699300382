import { CImage } from "@coreui/react";
import React from "react";

const ImageThumbnail = ({ src, alt }) => {
  return (
    <picture>
      {src.webp && <source srcSet={src.webp} type="image/webp" />}
      {src.avif && <source srcSet={src.avif} type="image/avif" />}
      <CImage
        src={src}
        alt={alt}
        thumbnail
        loading="lazy"
        className="p-0 object-fit-cover"
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </picture>
  );
};

export default React.memo(ImageThumbnail);
