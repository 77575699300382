import { CContainer } from "@coreui/react";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Headroom from "react-headroom";
import { useLocation } from "react-router-dom";
import '../../App.css';
import useTheme from "../../hooks/useTheme";
import useApiDataStore from "../../useApiDataStore";
import Banner from "./components/Banner";
import CategoriesNav from "./components/CategoriesNav";
import FoodItemList2 from "./components/FoodItemList2";
import MenuLoader from "./components/MenuLoader";
import Loader from "../../components/Loader";

const Menu = () => {
  const { info, menu, categories } = useApiDataStore();
  const theme = useTheme();
  let { state } = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [focusedCategory, setFocusedCategory] = useState(null);

  // Check if the user came from the Start screen
  useEffect(() => {
    if (state?.fromStart) {
      setSelectedCategory(state?.category);
      setShouldAnimate(true);
    }
  }, [state]);

  useEffect(() => {
  }, [selectedCategory]);

  const bannerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 5 } },
  };

  const contentVariants = {
    hidden: { y: 50 },
    visible: { y: 0, transition: { duration: 5.8 } },
  };

  if (!theme) {
    return <Loader color={theme?.palette?.primary.main || theme?.palette?.primary} />;
  }

  document.title = `${info.name} - Menu`;

  return (
    menu ? (
      <CContainer id='headroom-parent' className="g-0" style={{ background: theme.palette.background, paddingBottom: '10vh', overflowY: "scroll", overflowX: "hidden" }}>
        <motion.div
          initial={shouldAnimate ? "hidden" : "visible"}
          animate="visible"
          variants={bannerVariants}
        >
          <Banner />
        </motion.div>
        <Headroom
          className="g-0"
          style={{ background: theme.palette.background }}
          pinStart={200}
          disable={false}
          disableInlineStyles={false}
          downTolerance={450}   // How far down the user can scroll before hiding
          upTolerance={10}     // How far up the user must scroll before showing
        >
          <motion.div
            initial={shouldAnimate ? "hidden" : "visible"}
            animate="visible"
            variants={contentVariants}
          >
            <CategoriesNav categories={categories} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} focusedCategory={focusedCategory} />
          </motion.div>
        </Headroom>
        <motion.div
          initial={shouldAnimate ? "hidden" : "visible"}
          animate="visible"
          variants={contentVariants}
        >
          <FoodItemList2 menuData={menu} categories={categories} currency={info.currency} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} setFocusedCategory={setFocusedCategory} />
        </motion.div>
      </CContainer>
    ) : (
      <MenuLoader />
    )
  );
};

export default React.memo(Menu);
