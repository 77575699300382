import { CCard, CCardImage, CCardImageOverlay, CCardTitle, CCol, CContainer, CImage, CRow } from "@coreui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";
import forknifeLogo from "../../assets/images/forknife.png";
import useApiDataStore from "../../useApiDataStore";
import useTheme from "../../hooks/useTheme";

const Start3 = () => {
    const { supercategories, info } = useApiDataStore();
    const theme = useTheme();
    const [selectedCard, setSelectedCard] = useState(null);

    useEffect(() => {
        const shouldAddFontLink = true;

        if (shouldAddFontLink) {
            let linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,800&display=swap';

            document.head.appendChild(linkElement);

            return () => {
                document.head.removeChild(linkElement);
            };
        }
    }, []);

    const imageStyle = {
        width: '50%',
        aspectRatio: '1/1',
        objectFit: 'cover',
    };

    const cellStyle = {
        position: 'relative',
        overflow: 'hidden',
        height: '25vh',
    };

    const cardImageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    };

    const gradientStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
    };

    const overlayStyle = {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
    };

    const textStyle = {
        color: 'white',
        fontFamily: 'Playfair Display, serif',
        fontWeight: 800,
        fontSize: '2em'
    };

    const handleCardClick = (index) => {
        setSelectedCard(selectedCard === index ? null : index);
        sessionStorage.setItem("start", true);  // Set a cookie natively
    };

    const w = window.innerWidth;
    const h = window.innerHeight;

    if (!theme) {
        return (
            <CContainer sm className="g-0" style={{ height: '100%', width: '100%', display: 'block' }}>
                <CCol style={{ height: '85%', width: '100%' }} className="p-2 pt-5">
                    <ContentLoader
                        speed={2}
                        width={w}
                        height={h}
                        viewBox={`0 0 ${w} ${h}`}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <circle cx={window.innerWidth / 2} cy="110" r="90" />
                        <rect x="20" y="250" rx="3" ry="3" width="80%" height="20" />
                        <rect x="20" y="280" rx="3" ry="3" width="350" height="10" />
                        <rect x="20" y="300" rx="3" ry="3" width="100" height="30" />
                    </ContentLoader>
                </CCol>
                <CCol style={{ height: '10%' }} className="m-2 p-2 d-flex flex-row justify-content-end align-items-center">
                    <ContentLoader
                        speed={2}
                        width={400}
                        height={50}
                        viewBox="0 0 400 50"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <rect x="20" y="10" rx="3" ry="3" width="250" height="10" />
                        <rect x="300" y="10" rx="3" ry="3" width="30" height="30" />
                    </ContentLoader>
                </CCol>
            </CContainer>
        );
    }

    return (
        <CContainer
            sm
            className="g-0"
            style={{
                height: '100%',
                background: theme.startScreenBackgroundImage ? `url('${theme.startScreenBackgroundImage.original.jpg}')` : theme.backgroundColor,
                backgroundSize: theme.startScreenBackgroundImage ? `cover` : null,
                color: theme.textColor, display: "block"
            }}
        >
            <CCol
                style={{ height: "85%", width: '100%' }}
                className="d-flex flex-column justify-content-center align-items-center p-2 pt-1"
            >
                <picture className="w-100 d-flex justify-content-center align-items-center">
                    {theme.logo.webp && <source srcSet={theme.logo.webp} type="image/webp" />}
                    {theme.logo.avif && <source srcSet={theme.logo.avif} type="image/avif" />}
                    {theme.logo.jpg && <source srcSet={theme.logo.jpg} type="image/avif" />}
                    <CImage
                        srcSet={`${theme.logo.jpg},${theme.logo.webp}`}
                        style={imageStyle}
                    />
                </picture>

                <CContainer>
                    <AnimatePresence>
                        <CRow>
                            {supercategories.map((category, index) => (
                                <CCol xs="6" className="mb-4" key={index}>
                                    <motion.div
                                        style={cellStyle}
                                        animate={{
                                            opacity: selectedCard === index ? 1 : 0.8,
                                            scale: selectedCard === index ? 1.25 : 1,
                                        }}
                                        initial={{ opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                        onHoverStart={() => handleCardClick(index)}
                                        onHoverEnd={() => handleCardClick(-1)}
                                    >
                                        <Link to="menu" preventScrollReset={true} state={{ category: category.categoryId }} className="w-100" style={{ color: theme.primaryColor }}>
                                            <CCard className="w-100 h-100 mb-3 bg-dark text-white">
                                                <picture className="w-100 h-100">
                                                    {category.displayImage.webp && <source srcSet={category.displayImage.webp} type="image/webp" />}
                                                    {category.displayImage.avif && <source srcSet={category.displayImage.avif} type="image/avif" />}
                                                    <CCardImage
                                                        src={category.displayImage}
                                                        className="w-100"
                                                        style={cardImageStyle}
                                                    />
                                                </picture>
                                                <div style={gradientStyle}></div>
                                                <CCardImageOverlay style={overlayStyle}>
                                                    <CCardTitle style={textStyle}>{category.displayName}</CCardTitle>
                                                </CCardImageOverlay>
                                            </CCard>
                                        </Link>
                                    </motion.div>
                                </CCol>
                            ))}
                        </CRow>
                    </AnimatePresence>

                </CContainer>
            </CCol >
            <CCol
                style={{ height: "10%" }}
                className="m-2 p-2 d-flex flex-row justify-content-end align-items-center"
            >
                <div style={{ flex: 1 }}>
                    Powered by&nbsp;&nbsp;&nbsp;
                    <a href="https://welcome.forknife.app/"><CImage src={forknifeLogo} style={{ height: 23.92, width: 117.74 }} /></a>
                    <br />
                    {info.address.country === "Algeria" && <span>
                        Made in 🇩🇿 with ❤️
                    </span>}
                </div>
            </CCol>
            {/* <CCol
                style={{ height: "10%" }}
                className="m-2 p-2 d-flex flex-row justify-content-end align-items-center"
            >
                <div style={{ flex: 1 }}>
                    Powered by&nbsp;&nbsp;&nbsp;
                    <a href="https://welcome.forknife.app/"><CImage src={forknifeLogo} style={{ height: 23.92, width: 117.74 }} /></a>
                    <br />
                    {info.address.country === "Algeria" && <span>
                        Made in 🇩🇿 with ❤️
                    </span>}
                </div>
            </CCol> */}
        </CContainer >
    );
};

export default React.memo(Start3);
