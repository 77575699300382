import { CCard, CCardImage, CCardImageOverlay, CCardTitle, CCol, CContainer, CImage, CRow } from "@coreui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useTheme from "../../hooks/useTheme";
import useApiDataStore from "../../useApiDataStore";
import Loader from "../../components/Loader";

function getLogoColor(backgroundColor) {
    // Convert HEX to RGB
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16) / 255;
    const g = parseInt(hex.substring(2, 4), 16) / 255;
    const b = parseInt(hex.substring(4, 6), 16) / 255;

    // Apply the luminance formula
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Return white or black based on luminance
    return luminance > 0.5
        ? 'https://fknf.fra1.cdn.digitaloceanspaces.com/logos/forknife-logo-black-text.png'
        : 'https://fknf.fra1.cdn.digitaloceanspaces.com/logos/forknife-logo-white-text.png';
}



const Start2 = () => {
    const { branding, supercategories, info, order, isWsConnected, connectToWebSocket, tableIdOrOrderId, loading } = useApiDataStore();
    const theme = useTheme();
    const [selectedCard, setSelectedCard] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const shouldAddFontLink = true;

        if (shouldAddFontLink) {
            let linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,800&display=swap';

            document.head.appendChild(linkElement);

            return () => {
                document.head.removeChild(linkElement);
            };
        }
    }, []);

    const imageStyle = {
        width: '50%',
        aspectRatio: '1/1',
        objectFit: 'cover',
    };

    const cellStyle = {
        position: 'relative',
        overflow: 'hidden',
        height: '25vh',
    };

    const cardImageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))'
    };

    const gradientStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
    };

    const overlayStyle = {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
    };

    const textStyle = {
        color: 'white',
        fontFamily: 'Playfair Display, serif',
        fontWeight: 800,
        fontSize: '2em'
    };

    const handleCardClick = (index) => {
        setSelectedCard(selectedCard === index ? null : index);
    };

    if (loading || !theme || !order.id) {
        return <Loader color={theme?.palette?.primary.main || theme?.palette?.primary} />;
    }

    if (supercategories.length === 0) {
        navigate("../menu");
    }

    return (
        <CContainer
            sm
            className="g-0"
            style={{ height: '100%', backgroundColor: theme.palette.background, color: theme.textColor, display: "block" }}
        >
            <CCol
                style={{ height: "85%", width: '100%' }}
                className="d-flex flex-column justify-content-center align-items-center p-2 pt-1"
            >
                <picture className="w-100 d-flex justify-content-center align-items-center">
                    {branding.logo.webp && <source srcSet={branding.logo.webp} type="image/webp" />}
                    {branding.logo.avif && <source srcSet={branding.logo.avif} type="image/avif" />}
                    {branding.logo.jpg && <source srcSet={branding.logo.jpg} type="image/avif" />}
                    <CImage
                        srcSet={`${branding.logo.jpg},${branding.logo.webp}`}
                        style={imageStyle}
                    />
                </picture>



                <CContainer>
                    <AnimatePresence>
                        <CRow>
                            {supercategories.map((supercategory, index) => (
                                <CCol xs="6" className="mb-4" key={index}>
                                    <motion.div
                                        style={cellStyle}
                                        animate={{
                                            opacity: selectedCard === index ? 1 : 0.8,
                                            scale: selectedCard === index ? 1.25 : 1,
                                        }}
                                        initial={{ opacity: 0 }}
                                        transition={{ duration: 0.5 }}
                                        onHoverStart={() => handleCardClick(index)}
                                        onHoverEnd={() => handleCardClick(-1)}
                                    >
                                        <Link
                                            to="../menu"
                                            preventScrollReset={true}
                                            state={{ category: supercategory.categoryId, fromStart: true }}
                                            className="w-100"
                                            style={{ color: theme.primaryColor }}
                                        >
                                            <CCard className="w-100 h-100 mb-3 bg-dark text-white">
                                                <picture className="w-100 h-100">
                                                    {supercategory.displayImage.webp && <source srcSet={supercategory.displayImage.webp} type="image/webp" />}
                                                    {supercategory.displayImage.avif && <source srcSet={supercategory.displayImage.avif} type="image/avif" />}
                                                    <CCardImage
                                                        src={supercategory.displayImage}
                                                        className="w-100"
                                                        style={cardImageStyle}
                                                    />
                                                </picture>
                                                <div style={gradientStyle}></div>
                                                <CCardImageOverlay style={overlayStyle}>
                                                    <CCardTitle style={textStyle}>{supercategory.displayName}</CCardTitle>
                                                </CCardImageOverlay>
                                            </CCard>
                                        </Link>
                                    </motion.div>
                                </CCol>
                            ))}
                        </CRow>
                    </AnimatePresence>
                </CContainer>
            </CCol>
            <CCol
                style={{ height: "10%" }}
                className="m-2 p-2 d-flex flex-row justify-content-end align-items-center"
            >
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', gap: 6 }}>
                    <a href="https://welcome.forknife.app/" style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <CImage
                            src={getLogoColor(branding.theme.palette.background)}
                            style={{ /*height: 23.92,*/ width: '50%' }}
                        />
                    </a>
                    <br />
                    {info.address.country === "Algeria" && <span style={{ color: theme.palette.textPrimary, marginTop: '-40px' }}>
                        Made in 🇩🇿 with ❤️
                    </span>}
                </div>
            </CCol>
        </CContainer>
    );
};

export default React.memo(Start2);
