import { CCol, CNav, CNavItem, CNavLink, CRow } from "@coreui/react";
import React, { useEffect, useState, useRef } from "react";
import './CategoriesNav.css';
import useTheme from "../../../hooks/useTheme";

const CategoriesNav = ({ categories, selectedCategory, setSelectedCategory, focusedCategory }) => {
  const theme = useTheme();
  const navRef = useRef(null);

  useEffect(() => {
    if (focusedCategory) {
      const navItem = document.querySelector(`[data-category-id="${focusedCategory}"]`);
      if (navItem) {
        navItem.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
      }
    }
  }, [focusedCategory]);

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  return (
    <CRow className="py-2 g-0 m-0 h-100" style={{ background: theme.palette.background }}>
      <CCol>
        <CNav
          ref={navRef}
          id="h-nav"
          style={{
            width: '100%',
            borderBottom: `solid 1px ${theme.palette.textPrimary}`,
            justifyContent: 'start',
            borderColor: theme.palette.textPrimary,
            background: theme.palette.background,
            display: 'flex',
            scrollbarWidth: 'none',
            overflowX: 'scroll',
            flexWrap: 'nowrap',
            overflowY: 'hidden',
          }}
        >
          {categories.map((category) => (
            <CNavItem
              key={category.id}
              style={{
                display: 'flex',
                whiteSpace: 'nowrap !important',
                //borderColor: theme.palette.textPrimary,
                borderBottom: `solid 3px ${focusedCategory === (category.id) ? (theme?.palette?.primary.main || theme?.palette?.primary) : theme.palette.textPrimary}`,
              }}
            >
              <CNavLink
                data-category-id={category.id}
                onClick={() => handleCategoryClick(category.id)}
                style={{
                  color: focusedCategory === (category.id) ? (theme?.palette?.primary.main || theme?.palette?.primary) : theme.palette.textPrimary,
                  fontWeight: "bold",
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  borderColor: theme.palette.textPrimary,
                  borderBottom: `solid ${focusedCategory === (category.id) ? '3px' : "0px"}
                  ${focusedCategory === (category.id) ? (theme?.palette?.primary.dark || theme?.palette?.primary) : theme.palette.textPrimary}`,
                }}
              >
                {(category.shortName && category.shortName !== "") ? category.shortName : category.displayName}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
      </CCol>
    </CRow>
  );
};

export default React.memo(CategoriesNav);