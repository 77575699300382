const dev = {
    "API_URL": "http://localhost:8080",
    "DEMO_EATERY_ID": "65aeeae55ff43d39fb50a90c",
    "DEMO_TABLE_ID": "66b61cf52673ec5516266540"
}

const staging = {
    "API_URL": "https://api.staging.forknife.app",
    "DEMO_EATERY_ID": "65aeeae55ff43d39fb50a90c"
}

const prod = {
    "API_URL": "https://api.staging.forknife.app",
    "DEMO_EATERY_ID": "65aeeae55ff43d39fb50a90c"
}

export const config = process.env.NODE_ENV === 'development' ? dev : process.env.NODE_ENV === 'production' ? prod : staging;