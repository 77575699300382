import { cilCheckAlt, cilHandPointUp, cilLockLocked, cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
    CButton,
    CCard,
    CCardBody,
    CCardImage,
    CCardTitle,
    CCol,
    CRow,
    CToast,
    CToastBody,
    CToaster
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useApiDataStore from "../../../useApiDataStore";
import ImageThumbnail from "./ImageThumbnail";
import './FoodItemCard.css'
import useTheme from "../../../hooks/useTheme";

const FoodItemCard2 = ({ id, isSelected }) => {
    const { order, setOrder, menu, currency } = useApiDataStore();
    const theme = useTheme();
    const [showCardOverlay, setShowCardOverlay] = useState(false);
    const [toastVisible, setToastVisible] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
    const navigate = useNavigate();

    // Get the food item from the menu using the id
    const foodItem = Object.values(menu).flat().find(item => item.id === id);
    const {
        name,
        description,
        price,
        photo,
        otherSizes,
        requiredChoices,
        extras,
        popular,
        newItem,
        outOfStock,
        additionalNote,
        tags,
    } = foodItem;

    useEffect(() => {
        const hasViewedAnimation = localStorage.getItem('hasViewedAnimation');

        if (!hasViewedAnimation) {
            setShowCardOverlay(true);
            localStorage.setItem('hasViewedAnimation', 'true');
            setTimeout(() => {
                setShowCardOverlay(false);
            }, 5000);
        }
    }, []);

    const toggleSelection = (e) => {
        // e.stopPropagation();
        e.preventDefault()

        if (outOfStock) return;
        if (order?.status === 'VALIDATED') {
            setToastMessage("La commande de cette table a déjà été validée."
                + " Seul un serveur peut la modifier.");
            setToastVisible(true);
            return;
        }
        if (order?.status === 'CONFIRMED') {
            setToastMessage("Vous avez déjà confirmé votre commande."
                + " Vous pouvez l'éditer dans le menu \"Commande\" en cliquant sur le bouton \"Editer ma commande\".");
            setToastVisible(true);
            return;
        }

        // Check if the food item has required choices or other sizes
        if (hasRequiredChoicesOrOtherSizes()) {
            navigateToDetails();
        } else {
            if (isSelected) {
                removeFromOrder();
            } else {
                addToOrder();
            }
        }
    };

    const hasRequiredChoicesOrOtherSizes = () => {
        return (requiredChoices && Object.keys(requiredChoices).length > 0) ||
            (otherSizes && Object.keys(otherSizes).length > 0);
    };

    const navigateToDetails = () => {
        navigate(`item/${id}`, {
            state: {
                id: id,
                name: name,
                description: description,
                price,
                photo: photo,
                otherSizes,
                requiredChoices,
                extras,
                additionalNote,
                popular: popular,
                newItem,
                outOfStock: outOfStock,
                tags: tags,
                fromQuickAdd: true,
            }
        });
    };

    function addToOrder() {
        const itemKey = `${foodItem.id}`;

        const itemIndex = order ? order.foodItems.findIndex(item => item.key === itemKey) : -1;

        if (itemIndex === -1) {
            const newItem = {
                id: id,
                name: foodItem.name,
                key: itemKey,
                quantity: 1,
                otherSizes: {},
                requiredChoices: {},
                extras: {},
                additionalNote: null,
                totalPrice: null,
            };
            order.foodItems.push(newItem);
        } else {
            order.foodItems[itemIndex].quantity += 1;
        }
        setOrder({ ...order });
    }

    function removeFromOrder() {
        const itemIndex = order.foodItems.findIndex(item => {
            return item.id === id;
        });

        if (itemIndex !== -1) {
            if (order.foodItems[itemIndex].quantity > 1) {
                order.foodItems[itemIndex].quantity -= 1;
            } else {
                order.foodItems.splice(itemIndex, 1);
            }
            setOrder({ ...order });
        }
    }

    function prettyPrintPrice() {
        const maxPrice = otherSizes ? Math.max(...Object.values(otherSizes)) : null;
        const minPrice = otherSizes ? Math.min(...Object.values(otherSizes)) : null;
        const maxPriceString = maxPrice !== null ? `${maxPrice}` : "";
        const minPriceString = minPrice !== null ? `${minPrice}` : "";
        const dash = (minPrice && maxPrice) ? '-' : '';
        return `${minPrice < price ? minPriceString : price}${dash}${maxPrice < price ? price : maxPriceString}`;
    }

    return (
        <CRow xs={{ cols: 12 }} sm={{ cols: 12 }} md={{ cols: 8 }} style={{ backgroundColor: theme.palette.background }}>
            <CCol>
                <Link
                    to={`item/${id}`}
                    key={id}
                    className="text-decoration-none"
                    state={{
                        id: id,
                        name: name,
                        description: description,
                        price,
                        photo: photo,
                        otherSizes,
                        requiredChoices,
                        extras,
                        additionalNote,
                        popular: popular,
                        newItem,
                        outOfStock: outOfStock,
                        tags: tags,
                    }}
                >
                    <CCard
                        className={`px-2 py-3 mx-1 mb-2`}
                        style={{
                            background: `${isSelected ? "transparent" : "transparent"}`,
                            boxShadow: `0px 0px ${isSelected ? (theme.shadow || 4) : 4}px ${isSelected ? (theme?.palette?.primary.main || theme?.palette?.primary) : "rgba(0,0,0,1)"}`,
                            minHeight: '116px',

                        }}
                    >
                        <CRow className="g-0 align-items-center mr-1">
                            <CCol xs={1} className='d-flex justify-content-center align-items-center'>
                                <CButton onClick={(e) => toggleSelection(e)} size='sm' className={`rounded-circle`} disabled={outOfStock === true}
                                    style={{
                                        background: isSelected ? (theme?.palette?.primary.main || theme?.palette?.primary) : "transparent",
                                        borderColor: theme.palette.textPrimary,
                                        color: isSelected ? theme.palette.onPrimary : theme.palette.textPrimary
                                    }}>
                                    {outOfStock === true && <CIcon icon={cilLockLocked} />}
                                    {outOfStock !== true && <CIcon icon={isSelected ? cilCheckAlt : cilPlus} />}
                                </CButton>
                            </CCol>
                            <CCol xs={!photo?.original ? 11 : 7} className="g-0 px-1 h-100">
                                <CCardBody className="px-1 mx-1" style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    gap: '16px',
                                }}>
                                    <CCol className=" pb-1" style={{ flex: 1 }}>
                                        <CCardTitle className="mb-0">
                                            <span className="pb-2" style={{
                                                /* M3/title/large */
                                                fontFamily: 'Merriweather',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '20px',
                                                lineHeight: '28px',
                                                // overflow: "hidden",
                                                // textOverflow: "ellipsis",
                                                // whiteSpace: "nowrap",
                                                display: 'block',
                                                color: `${isSelected ? (theme?.palette?.primary.main || theme?.palette?.primary) : theme.palette.textPrimary}`,
                                            }}>{name} {popular && '⭐'}</span>
                                        </CCardTitle>

                                        {newItem && (
                                            <div className="top-0 start-0 text-white px-2 py-1 mb-1"
                                                style={{
                                                    width: 'fit-content',
                                                    fontSize: '12px',
                                                    borderRadius: '4px',
                                                    backgroundColor: theme?.palette?.secondary,
                                                    color: theme?.palette?.onSecondary,
                                                }}>
                                                🔥 Nouveau! 🔥
                                            </div>
                                        )}
                                        <p
                                            className="my-1"
                                            style={{
                                                paddingRight: "1rem",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: 3,
                                                WebkitBoxOrient: "vertical",
                                                color: `${isSelected ? (theme?.palette?.primary.main || theme?.palette?.primary) : theme.palette.textPrimary}`,
                                                /* M3/body/medium */
                                                fontFamily: theme.typography.fonts[0].name,
                                                fontStyle: "normal",
                                                fontWeight: 400,
                                                fontSize: '14px',
                                                lineHeight: '20px',
                                                /* or 143% */
                                                letterSpacing: '0.25px',
                                            }}
                                        >
                                            {description}
                                        </p>
                                        <div className="mt-3 mb-1" style={{
                                            display: "flex",
                                            alignContent: "center",
                                            color: `${isSelected ? (theme?.palette?.primary.main || theme?.palette?.primary) : theme.palette.textPrimary}`,
                                            /* M3/body/small 0.4 */
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '13px',
                                            lineHeight: '16px',
                                            letterSpacing: '0.4px',


                                        }}>
                                            {prettyPrintPrice()} {currency || ""}
                                            {outOfStock && (<p className="text-danger" style={{
                                                fontWeight: "bold",
                                            }}>
                                                &nbsp;-&nbsp;Indisponible
                                            </p>)}
                                        </div>
                                    </CCol>
                                </CCardBody>
                            </CCol>
                            {photo?.original && (
                                <CCol xs={4} className="d-flex p-1 justify-content-center align-items-center" style={{ aspectRatio: "1/1", flexShrink: 0 }}>
                                    <CCardImage
                                        component={() => <ImageThumbnail src={photo.original} alt={name} />}
                                    />
                                </CCol>
                            )}
                            <div className={`card-overlay flex-column justify-content-center align-items-center mr-1`} style={{ display: showCardOverlay === true ? 'flex' : 'none', position: "absolute", x: 0, y: 0, color: "white", height: "100%", width: "95%", background: "rgb(0,0,0,0.5)", pointerEvents: "none" }}>
                                <CIcon icon={cilHandPointUp} color="white" size="xl" className='zoom-in-out' />
                                <p>Plus de détails</p>
                            </div>
                        </CRow>
                    </CCard>
                </Link>
            </CCol>
            <CToaster className="bottom-0 relative h-100 w-100 d-flex flex-column justify-content-end align-items-center" push={toastVisible ? (
                <CToast autohide={true} visible={toastVisible}
                    className="w-90 p-3 align-items-center "
                    style={{
                        backgroundColor: theme?.palette?.primary.main || theme?.palette?.primary,
                        color: theme.palette.onPrimary,
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        borderRadius: 0
                    }}
                >
                    <CToastBody style={{ fontSize: "large" }}>
                        {toastMessage}
                    </CToastBody>
                </CToast>
            ) : null} placement="top-end" />
        </CRow>
    );
};

export default React.memo(FoodItemCard2);
