import { CCol, CContainer, CImage, CRow } from "@coreui/react";
import React from "react";
import useApiDataStore from "../../../useApiDataStore";

const Banner = ({ header }) => {
  const { branding } = useApiDataStore();

  return (
    <div>
      <CRow>
        <picture>
          {branding.banner.webp && <source srcSet={branding.banner.webp} type="image/webp" />}
          {branding.banner.avif && <source srcSet={branding.banner.avif} type="image/avif" />}
          {branding.banner.jpg && <source srcSet={branding.banner.jpg} type="image/jpg" />}
          <CImage className="p-0 w-100" srcSet={`${branding.banner.jpg},${branding.banner.webp}`} style={{ borderBottomRightRadius: "1rem", borderBottomLeftRadius: "1rem", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)" }} />
        </picture>
      </CRow>
      <CRow className="px-2 pb-2" style={{ marginTop: "-100px" }}>
        <CCol
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-100px",
          }}
        >
          <CContainer className="g-0 text-center">
            {branding.showLogoInMenu &&
              <picture>
                {branding.logo.webp && <source srcSet={branding.logo.webp} type="image/webp" />}
                {branding.logo.avif && <source srcSet={branding.logo.avif} type="image/avif" />}
                {branding.logo.jpg && <source srcSet={branding.logo.jpg} type="image/jpg" />}
                <CImage
                  srcSet={`${branding.logo.jpg},${branding.logo.webp}`}
                  alt={"Logo"}
                  loading="lazy"
                  align="center"
                  style={{
                    background: "white",
                    borderRadius: "50%",
                    height: "20vh",
                    aspectRatio: "1/1",
                  }}
                />
              </picture>
            }
            <h3>{header}</h3>
          </CContainer>
        </CCol>
      </CRow>
    </div>
  );
};

export default React.memo(Banner);
