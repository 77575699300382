import { cilBasket, cilDinner } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCol, CContainer, CImage } from "@coreui/react";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTheme from '../../hooks/useTheme';
import useApiDataStore from '../../useApiDataStore';
import '../../css/loader.css';
import Loader from '../../components/Loader';

function getLogoColor(backgroundColor) {
    // Convert HEX to RGB
    const hex = backgroundColor.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16) / 255;
    const g = parseInt(hex.substring(2, 4), 16) / 255;
    const b = parseInt(hex.substring(4, 6), 16) / 255;

    // Apply the luminance formula
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

    // Return white or black based on luminance
    return luminance > 0.5
        ? 'https://fknf.fra1.cdn.digitaloceanspaces.com/logos/forknife-logo-black-text.png'
        : 'https://fknf.fra1.cdn.digitaloceanspaces.com/logos/forknife-logo-white-text.png';
}

const OrderTypePicker = () => {
    const { branding, info, supercategories, isWsConnected, order, table, connectToWebSocket, setOrderType } = useApiDataStore();
    const { tableIdOrOrderId } = useParams();
    const navigate = useNavigate();
    const theme = useTheme();
    const [disabled, setDisabled] = useState(false);

    const handleSelection = async (orderType) => {
        setDisabled(true);
        if (!isWsConnected) {
            setOrderType(orderType);
            await connectToWebSocket(info.id, tableIdOrOrderId);
            setTimeout(() => {
                setDisabled(false);
            }, 10000);
        }
    };

    useEffect(() => {
        if (isWsConnected && order.id) {
            setTimeout(() => {
                setDisabled(false);
            }, 10000);
            if (supercategories.length > 0) {
                navigate(`./start`);
            } else {
                navigate("./menu");
            }
        }
    }, [isWsConnected, order.id, supercategories, navigate]);

    const buttonStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        padding: '25% 15%',
        fontSize: '1.5rem',
        textAlign: 'center',
        border: 'none',
        backgroundColor: theme.palette.primary.dark || theme.palette.primary.main || theme.palette.primary,
        // textShadow: `0 0 10px ${theme.palette.success.main}, 0 0 20px ${theme.palette.success.main}`,
        color: theme.palette.onPrimary,
        filter: `drop-shadow(0 0 10px ${theme.palette.primary.main || theme.palette.primary})`,
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        borderRadius: '12px',
        cursor: 'pointer',
    };

    const iconStyle = {
        fontSize: '3rem',
        marginBottom: '10px',
    };

    const imageStyle = {
        width: '50%',
        aspectRatio: '1/1',
        objectFit: 'cover',
    };

    if (tableIdOrOrderId && !table?.id) {
        return <Loader color={theme?.palette?.primary} />
    }

    return (
        <CContainer
            fluid
            className="g-1 p-1 d-flex flex-column"
            style={{
                color: theme.palette.textPrimary,
                height: "100%",
                overflowY: "scroll",
                overflowX: "hidden",
                boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.8)",
                paddingBottom: '10vh',
                border: 'none',
            }}
        >
            <picture className="w-100 d-flex justify-content-center align-items-center">
                {branding.logo.webp && <source srcSet={branding.logo.webp} type="image/webp" />}
                {branding.logo.avif && <source srcSet={branding.logo.avif} type="image/avif" />}
                {branding.logo.jpg && <source srcSet={branding.logo.jpg} type="image/avif" />}
                <CImage
                    srcSet={`${branding.logo.jpg},${branding.logo.webp}`}
                    style={imageStyle}
                />
            </picture>
            <CCol className="w-100 d-flex flex-column justify-content-center align-items-center" style={{ gap: 10 }}>
                {table?.id && (
                    <CCol xs="6" className="d-flex justify-content-center align-items-center">
                        {!disabled ?
                            <CButton
                                style={buttonStyle}
                                onClick={() => handleSelection("IN_TABLE")}
                                disabled={disabled}
                            >
                                <CIcon icon={cilDinner} size='3xl' style={iconStyle} />
                                Sur place
                            </CButton>
                            : <Loader color={theme?.palette?.primary} />
                        }
                    </CCol>
                )}
                <CCol xs="6" className="d-flex justify-content-center align-items-center">
                    {!disabled ? <CButton
                        style={buttonStyle}
                        onClick={() => handleSelection("TAKE_OUT")}
                        disabled={disabled}
                    >
                        <CIcon icon={cilBasket} size='3xl' style={iconStyle} />
                        À emporter
                    </CButton>
                        : <Loader color={theme?.palette?.primary} />}
                </CCol>
            </CCol>
            <CCol
                style={{ height: "10%" }}
                className="m-2 p-2 d-flex flex-row justify-content-end align-items-center"
            >
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', gap: 6 }}>
                    <a href="https://welcome.forknife.app/"
                        style={{
                            display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center',
                            textDecoration: 'none',
                        }}
                    >
                        <CImage
                            src={getLogoColor(branding.theme.palette.background)}
                            style={{ maxWidth: '150px', height: 'auto' }}
                            className="cursor-pointer"
                        />
                        {info.address.country === "Algeria" && <span style={{ color: theme.palette.textPrimary, marginTop: '-30px' }}>
                            Made in 🇩🇿 with ❤️
                        </span>}
                    </a>
                </div>
            </CCol>
        </CContainer>
    );
};

export default OrderTypePicker;
