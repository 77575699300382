import { cilTrash } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
    CCard,
    CCardBody,
    CCol,
    CRow
} from "@coreui/react";
import React from "react";
import useTheme from "../../hooks/useTheme";
import useApiDataStore from "../../useApiDataStore";

const OrderItem = ({ id, itemKey, name, photo, price, currency, requiredChoices, otherSizes, extras, quantity, totalPrice, additionalNote }) => {
    const { order, setOrder } = useApiDataStore();
    const theme = useTheme();

    function updateQuantity(operation) {
        const updatedOrder = { ...order };
        const foodItemIndex = updatedOrder.foodItems.findIndex(item => item.key === itemKey);

        if (foodItemIndex !== -1) {
            const foodItem = updatedOrder.foodItems[foodItemIndex];

            if (operation === 'plus') {
                foodItem.quantity += 1;
            } else if (operation === 'minus') {
                foodItem.quantity -= 1;
                // If the quantity drops below 1, remove the item from the array
                if (foodItem.quantity < 1) {
                    updatedOrder.foodItems.splice(foodItemIndex, 1);
                }
            }

            setOrder(updatedOrder);
        } else {
            console.error(`Food item with key ${itemKey} not found.`);
        }
    }

    function handlePlus() {
        updateQuantity('plus');
    }

    function handleMinus() {
        updateQuantity('minus');
    }

    const renderExtras = () => {
        if (extras && Object.keys(extras).length > 0) {
            return (
                <CRow className="mt-0 mx-0">
                    <CCol xs={12}>
                        {/* <span style={{ fontWeight: "bold", fontSize: "12px" }}>Extras:</span> */}
                        {Object.keys(extras).map(extra => (
                            <p style={{ fontSize: "12px", marginBottom: 0 }} key={extra}>
                                +{extra} ({extras[extra]} {currency})
                            </p>
                        ))}
                    </CCol>
                </CRow>
            );
        }
        return null;
    };

    const renderRequiredChoices = () => {
        if (requiredChoices && Object.keys(requiredChoices).length > 0) {
            return (
                <CRow className="mt-0 mx-0">
                    <CCol xs={12}>
                        {/* //<span style={{ fontWeight: "bold", fontSize: "12px" }}>Choix requis:</span> */}
                        {/* <ul style={{ fontSize: "12px", marginLeft: "15px", listStyleType: "disc" }}> */}
                        {Object.entries(requiredChoices).map(([group, choice]) => (
                            <p style={{ fontSize: "12px", marginBottom: 0 }} key={group}>
                                {group}: {choice}
                            </p>
                        ))}
                        {/* </ul> */}
                    </CCol>
                </CRow>
            );
        }
        return null;
    };

    const renderOtherSizes = () => {
        if (otherSizes && Object.keys(otherSizes).length > 0) {
            return (
                <CRow className="mt-0">
                    <CCol className="mt-0" xs={12}>
                        {/* <span style={{ fontWeight: "bold", fontSize: "12px" }}>Autres tailles:</span> */}
                        {/* <ul style={{ fontSize: "12px", marginLeft: "15px", listStyleType: "disc" }}> */}
                        {Object.entries(otherSizes).map(([size, price]) => (
                            <p style={{ fontSize: "12px", marginTop: 0, marginBottom: 0 }} key={size}>
                                {size} {price > 0 ? `(${price} ${currency})` : ""}
                            </p>
                        ))}
                        {/* </ul> */}
                    </CCol>
                </CRow>
            );
        }
        return null;
    };

    const renderAdditionalNote = () => {
        if (additionalNote) {
            return (
                <CRow className="mt-1">
                    <CCol xs={12}>
                        <span style={{ fontWeight: "bold", fontSize: "12px" }}>Note supplémentaire:</span>
                        <p style={{ fontSize: "12px", marginLeft: "15px" }}>{additionalNote}</p>
                    </CCol>
                </CRow>
            );
        }
        return null;
    };

    return (
        <CRow className="g-2 mb-3">
            <CCol>
                <CCard
                    className={`mb-0 mx-1 px-2 border-0`}
                    style={{
                        backgroundColor: theme.palette.background,
                        color: theme.palette.textPrimary,
                    }}
                >
                    <CCardBody className="pt-0 px-0">
                        <CRow
                            className="gx-1 mb-0 w-100"
                        //style={{ justifyContent: 'flex-start', alignItems: 'flex-start' }}
                        >
                            <CCol
                                xs={8}
                                className="gx-3"
                                style={{
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <span
                                        style={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: "vertical",
                                            fontSize: '14px',
                                            lineHeight: '1.2',
                                            marginBottom: '-1px',
                                            fontWeight: 'bold',
                                            letterSpacing: 0.1,
                                        }}
                                    >
                                        {quantity}x{" "}{name}
                                    </span>
                                    <span
                                        className="mt-1 mx-1 mb-0 pb-0"
                                        style={{
                                            fontSize: 11,
                                            letterSpacing: 0.5,
                                            color: theme.palette.textPrimary,
                                        }}
                                    >
                                        {totalPrice} {currency}
                                    </span>
                                    {renderOtherSizes()}
                                </div>
                            </CCol>
                            {order.status === 'CREATED' && (<CCol
                                xs={4}
                                className="gx-0"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        padding: 1,
                                        width: '97px',
                                        height: '40px',
                                    }}
                                >
                                    <button
                                        onClick={handleMinus}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '4px 0px',
                                            width: '39px',
                                            height: '40px',
                                            border: '1px solid',
                                            background: theme.activeColor,
                                            borderColor: theme.borderColor,
                                            color: theme?.palette?.primary.main || theme?.palette?.primary,
                                            borderTopLeftRadius: 4,
                                            borderBottomLeftRadius: 4,
                                        }}
                                    >
                                        {quantity === 1 ? (
                                            <CIcon
                                                icon={cilTrash}
                                                size="sm"
                                                style={{ width: '18px', height: '18px', color: theme.palette.textPrimary }}
                                            />
                                        ) : (
                                            <span style={{ fontSize: '18px', color: theme.palette.textPrimary }}>-</span>
                                        )}
                                    </button>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '4px 0px',
                                            width: '39px',
                                            height: '40px',
                                            border: '1px solid',
                                            borderLeft: 0,
                                            borderRight: 0,
                                            background: theme.palette.background,
                                            borderColor: theme?.palette?.primary.main || theme?.palette?.primary,
                                            color: theme?.palette?.primary.main || theme?.palette?.primary,
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontFamily: 'Roboto',
                                                fontWeight: 500,
                                                fontSize: '11px',
                                                lineHeight: '16px',
                                                textAlign: 'center',
                                                color: theme.palette.textPrimary
                                            }}
                                        >
                                            {quantity}
                                        </span>
                                    </div>
                                    <button
                                        onClick={handlePlus}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '4px 0px',
                                            width: '39px',
                                            height: '40px',
                                            border: '1px solid',
                                            background: theme.activeColor,
                                            borderColor: theme.borderColor,
                                            color: theme?.palette?.primary.main || theme?.palette?.primary,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                        }}
                                    >
                                        <span style={{ fontSize: '18px', color: theme.palette.textPrimary }}>+</span>
                                    </button>
                                </div>
                            </CCol>)}
                            {order.status !== 'CREATED' && (<CCol
                                xs={3}
                                className="gx-0"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                }}
                            >
                            </CCol>)}
                        </CRow>
                        <CRow className="w-100">
                            {renderRequiredChoices()}
                            {renderExtras()}
                            {renderAdditionalNote()}
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

    );
};

export default React.memo(OrderItem);